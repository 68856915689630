import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import { RegisterFlow, RegisterIntro } from '../../../components/views/layout'
import { Input } from '../../../components/common/form/fields'
import { Button, LoadingOverlay, Seo } from '../../../components/common'
import { useAPI, useForm, useLogin, useRegister } from '../../../hooks'
import { Paths } from '../../../constants/structure'

import { registerFields } from '../../../common/utils/register'
import { step2aSchema } from '../../../common/utils/register'
import { isEmptyObject } from '../../../common/utils/functions'
import { PATH } from '../../../constants/global'
import { Profile } from '../../../hooks/useProfile/useProfile.type'

const COMPANY_TELEPHONE = process.env.APP_TELEPHONE

const ValidatePage = () => {
   const request = useAPI()
   const [profile, setProfile] = useState<Profile | null>(null)

   const { error, isLoading, isSuccess: isLoginSuccess, login } = useLogin()
   const { formState, update } = useRegister()
   const { onSubmit, validationErrors } = useForm(step2aSchema, (values) => {
      update(values)
      login(formState?.email, values?.password)
   })

   const isInvalid =
      isEmptyObject(formState) || !formState[registerFields.EMAIL]

   useEffect(() => {
      if (isInvalid) {
         navigate(Paths.REGISTER)
      }
   }, [])

   useEffect(() => {
      if (isLoginSuccess) {
         getProfile() // need to refresh profile to update form
      }
   }, [isLoginSuccess])

   useEffect(() => {
      if (profile && profile?.Company) {
         handleSuccessfulLogin()
      }
   }, [profile])

   const getProfile = async () => {
      try {
         const { data } = await request.get(PATH.PROFILE.GET)
         if (data) {
            setProfile(data)
         }
      } catch (error) {
         console.log(error)
         return navigate(Paths.REGISTER)
      }
   }

   // if profile exists then set form data and navigate to next screen
   const handleSuccessfulLogin = () => {
      update({
         ...formState,
         city: profile?.Address?.City,
         stateProvince: profile?.Address?.State,
         company: profile?.Company,
         firstName: profile?.Firstname,
         lastName: profile?.Lastname,
         phoneNumber: profile?.PhoneNumber,
      })

      return navigate(Paths.REGISTER_UPGRADE)
   }

   return (
      <RegisterFlow title="Step 2">
         <Seo title="Register - Step 2" />

         {isInvalid ? (
            <LoadingOverlay />
         ) : (
            <>
               <RegisterIntro>
                  We have found an existing account which uses your e-mail
                  address. Please enter your password to continue setting up an
                  Enterprise Trial.
                  <br />
                  <br />
                  If you need assistance please call {COMPANY_TELEPHONE} and
                  press 2 for support.
               </RegisterIntro>

               <form noValidate onSubmit={onSubmit}>
                  <Input
                     id={registerFields.PASSWORD}
                     inputProps={{
                        type: 'password',
                     }}
                     label="Password"
                     required
                     error={validationErrors[registerFields.PASSWORD] || error}
                  />

                  <Button loading={isLoading}>Next</Button>
               </form>
            </>
         )}
      </RegisterFlow>
   )
}

export default ValidatePage
